<script>
import VxLoading from "@/components/vx/VxLoading";
import VxDialogView from "@/components/vx/VxDialogView";

import { format, parseISO } from "date-fns";
import { snakeCase } from "lodash";
import { hasSnackbarAccess } from "@/mixins/ui";
import { VxAlert } from "@/core-ui";
import { materialAbsoluteDateTimeString } from "@/utils/datetime";
import Bus from "@/utils/events";

import { COUPON_DETAIL, COUPON_PRINT, REDEEM_COUPON } from "./graphql/queries";

export default {
  name: "BouncebackShowDialog",
  filters: {
    datetime(v) {
      if (v) {
        if (typeof v === "string") {
          return format(parseISO(v), "h:mm aaa 'on' M/d/yyyy");
        } else {
          return format(v, "h:mm aaa 'on' M/d/yyyy");
        }
      } else {
        return "";
      }
    },
  },
  components: {
    VxLoading,
    VxDialogView,
    VxAlert,
  },
  mixins: [hasSnackbarAccess],
  props: {
    id: { type: String, default: "" },
  },
  data: () => ({
    editMode: false,
    coupon: {
      cellphone: undefined,
      createdAt: undefined,
      email: undefined,
      id: undefined,
      loading: false,
      name: undefined,
      number: undefined,
      redeemAmount: undefined,
      redeemEndOn: undefined,
      redeemMinSpend: undefined,
      redeemStartOn: undefined,
      redeemable: undefined,
      redeemed: undefined,
      storeName: undefined,
      transactionId: undefined,
      voided: undefined,
    },
    apolloLoading: 0,
    printLoading: false,
    redeemLoading: false,
  }),
  apollo: {
    coupon: {
      loadingKey: "apolloLoading",
      query: COUPON_DETAIL,
      variables() {
        return { id: this.id };
      },
      skip() {
        return !this.id;
      },
      update({ coupon }) {
        coupon.loading = false;
        return coupon;
      },
    },
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    active() {
      return !!this.coupon.id;
    },
    redeemAmountMessage() {
      if (!this.active) return "";
      return this.$t("bounceback.app.bouncebackShow.redeemAmountText", {
        redeemAmount: parseInt(this.coupon.redeemAmount),
        redeemMinSpend: parseInt(this.coupon.redeemMinSpend),
      });
    },
    redeemStartOn() {
      if (!this.active) return "";
      if (!this.coupon.redeemStartOn) return "";
      return this.coupon.redeemStartOn;
    },
    redeemEndOn() {
      if (!this.active) return "";
      if (!this.coupon.redeemEndOn) return "";
      return this.coupon.redeemEndOn;
    },
    redeemedAt() {
      return materialAbsoluteDateTimeString(this.coupon.redeemedAt);
    },
    expiredAt() {
      if (this.coupon.status != "expired") {
        return null;
      }
      return materialAbsoluteDateTimeString(this.coupon.redeemEndOn);
    },
    redeemableMessage() {
      if (!this.active) return "";
      return this.$t("bounceback.app.bouncebackShow.reedemableMessage", {
        redeemStartOn: format(parseISO(this.redeemStartOn), "MMM d"),
        redeemEndOn: format(parseISO(this.redeemEndOn), "MMM d, yyyy"),
      });
    },
    storeInfo() {
      if (!this.active) return "";
      if (!this.coupon.store) return "";
      return `${this.coupon.storeName}`;
    },
    expiresAtDateText() {
      if (!this.coupon) {
        return "";
      }
      return format(parseISO(this.coupon.redeemEndOn), "M/d/yyyy");
    },
  },
  methods: {
    async onPrintClick(coupon) {
      // Show a snackbar message if raiPos is not available
      if (!this.$raiPos) {
        this.showSnackbar({
          text: this.$t("bounceback.app.bouncebackShow.reprintPOSWarning"),
        });
        return false;
      }
      this.printLoading = true;
      const id = this.coupon.id || this.$route.params.id;
      try {
        // Get the printer json from backend
        const {
          data: { coupon: data },
        } = await this.$apollo.query({
          query: COUPON_PRINT,
          variables: { id },
        });
        // Turn all nulls into empty strings
        const resp = Object.keys(data).reduce((acc, key) => {
          if (key === "id") return acc;
          acc[snakeCase(key)] = data[key] || "";
          return acc;
        }, {});
        this.$raiPos.printBounceBack(JSON.stringify(resp));
      } catch (err) {
        // Show snackbar message if either backend call or RaiExt calls fail
        this.showSnackbar({
          text: this.$t("bounceback.app.bouncebackShow.reprintError", {
            id,
            error: err,
          }),
        });
        console.log(err);
      }
      this.printLoading = false;
    },
    async onRedeemClick() {
      this.redeemLoading = true;

      try {
        const {
          data: {
            redeemCoupon: { coupon, errors },
          },
        } = await this.$apollo.mutate({
          mutation: REDEEM_COUPON,
          variables: { id: this.coupon.id },
        });
        if (errors.length) {
          throw new Error(errors.join("; "));
        }
        this.showSnackbar({ text: `Coupon redeemed successfully` });
        this.$router.go(-1);
        Bus.$emit("coupon-redeemed", coupon);
      } catch (error) {
        this.showSnackbar({
          text: this.$t("bounceback.app.bouncebackShow.redeemError", { error }),
        });
        console.error(error);
      }
      this.redeemLoading = false;
    },
    onEditClick() {
      this.$router.replace({
        name: "bounceback_edit",
        params: { id: this.coupon.id },
        preserveQuery: true,
      });
    },
  },
};
</script>

<style>
table th {
  text-align: left;
}
.card .card__actions .btn .btn__content .icon {
  color: rgba(0, 0, 0, 0.54);
}
</style>

<template>
  <VxDialogView
    :title="$t('bounceback.app.bouncebackShow.title')"
    :retain-focus="false"
    :v-size="'medium'"
    :h-size="'xsmall'"
  >
    <VxLoading v-if="!!apolloLoading" />
    <template v-if="!!coupon && !!coupon.id" #actions>
      <v-btn
        v-if="!isSmallScreen"
        text
        icon
        color="accent"
        :loading="coupon.printLoading"
        @click="onPrintClick"
      >
        <v-icon v-text="'$vuetify.icons.print'"></v-icon>
      </v-btn>

      <v-btn
        text
        :color="isSmallScreen ? null : 'primary'"
        @click="onEditClick"
        >{{ $t("bounceback.app.bouncebackShow.editButton") }}</v-btn
      >

      <v-btn
        text
        :color="isSmallScreen ? null : 'primary'"
        :disabled="!coupon.redeemable"
        :loading="redeemLoading"
        @click="onRedeemClick"
        >{{ $t("bounceback.app.bouncebackShow.redeemButton") }}</v-btn
      >
    </template>
    <div v-if="!apolloLoading">
      <!-- TODO: Jaxn, add instructions copy -->
      <!-- Redeemable details -->
      <div>
        <p class="text-body-2 mb-0">{{ redeemAmountMessage }}</p>
        <p class="text-body-2">{{ redeemableMessage }}</p>
      </div>

      <v-divider class="mb-4" />

      <table>
        <tbody>
          <tr>
            <th>{{ $t("bounceback.app.bouncebackShow.redeeambleAt") }}</th>
            <td class="pl-2">{{ storeInfo }}</td>
          </tr>
          <tr>
            <th>{{ $t("bounceback.app.bouncebackShow.issuedTo") }}</th>
            <td class="pl-2">{{ coupon.name }}</td>
          </tr>
          <tr>
            <th>{{ $t("bounceback.app.bouncebackShow.issuedAt") }}</th>
            <td class="pl-2">{{ coupon.createdAt | datetime }}</td>
          </tr>
          <tr>
            <th>{{ $t("bounceback.app.bouncebackShow.transNo") }}</th>
            <td class="pl-2">{{ coupon.transactionId }}</td>
          </tr>
          <tr>
            <th>{{ $t("bounceback.app.bouncebackShow.terminal") }}</th>
            <td class="pl-2">&lt;terminal goes here&gt;</td>
          </tr>
          <tr>
            <th>{{ $t("bounceback.app.bouncebackShow.employee") }}</th>
            <td class="pl-2">&lt;employee goes here&gt;</td>
          </tr>
          <tr>
            <th>{{ $t("bounceback.app.bouncebackShow.couponNo") }}</th>
            <td class="pl-2">{{ coupon.number }}</td>
          </tr>
        </tbody>
      </table>

      <v-divider class="mt-4" />

      <!-- Terms and conditions -->
      <p class="text-caption mt-4">
        {{
          $t("bounceback.app.bouncebackShow.termsConditions", {
            redeemAmount: parseInt(coupon.redeemAmount),
            redeemMinSpend: parseInt(coupon.redeemMinSpend),
            expiresAt: expiresAtDateText,
          })
        }}
      </p>

      <VxAlert v-if="coupon.redeemed" type="info">
        {{
          $t("bounceback.app.bouncebackShow.redeemedText", {
            redeemedAt,
          })
        }}
      </VxAlert>
      <VxAlert v-else-if="expiredAt" type="info">
        {{
          $t("bounceback.app.bouncebackShow.expiredText", {
            expiredAt,
          })
        }}
      </VxAlert>
    </div>
  </VxDialogView>
</template>
